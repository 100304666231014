import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import error from "../img/error.png";
import { colors } from "../components/GlobalStyles";

const StyledErrorPage = styled.div`
  background: url(${error});
  width: 100%;
  height: 100vh;
  position: relative;
`;

const TextBox = styled.div`
  margin: 400px auto;
  width: 100%;
  position: absolute;
`;

const TextContainer = styled.div`
  width: 380px;
  height: 280px;
  text-align: center;
  color: ${colors.black};
  background-color: ${colors.white};
  border: 2px solid ${colors.black};
  margin: 0 auto;

  h2 {
    margin-top: 40px;
  }
`;

const NotFoundPage = () => {
  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    setLanguage(localStorage.getItem("language") || "pl");
  }, []);

  const handleLanguageClick = () => {
    if (language === "pl") {
      setLanguage("eng");
    } else {
      setLanguage("pl");
    }
  };

  return (
    <Layout
      language={language === "pl" ? "eng" : "pl"}
      handleLanguageClick={handleLanguageClick}
    >
      <StyledErrorPage>
        <TextBox>
          <TextContainer>
            <h2>404</h2>
            <p>page not found</p>
          </TextContainer>
        </TextBox>
      </StyledErrorPage>
    </Layout>
  );
};

export default NotFoundPage;
